/* CARDS PAPER */
.card {
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5);
  color: #FFF;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  width: 350px; }

.card .title,
.card .stats,
.card .category,
.card .description,
.card .social-line,
.card .actions,
.card .content,
.card .card-footer,
.card small,
.card a {
  position: relative;
  z-index: 3; }

.card a {
  color: #444444; }

.card a:hover,
.card a:focus {
  color: #333333; }

.card img {
  max-width: 100%;
  height: auto; }

.card[data-radius="none"] {
  border-radius: 0px; }

.card[data-radius="none"] .header {
  border-radius: 0px 0px 0 0; }

.card[data-radius="none"] .header img {
  border-radius: 0px 0px 0 0; }

.card.card-plain {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0; }

.card.card-with-shadow {
  box-shadow: 0 20px 16px -15px rgba(165, 165, 165, 0.57); }

.card .btn {
  text-shadow: none;
  font-weight: bold; }

.card .title-uppercase {
  text-transform: uppercase; }

.card .header {
  position: relative;
  border-radius: 3px 3px 0 0;
  z-index: 3; }

.card .header.header-with-icon {
  height: 150px; }

.card .header img {
  width: 100%;
  /*
                      @include opacity(0);
                      display: none;
          */ }

.card .header .category {
  padding: 15px; }

.card .content .price {
  border: 2px solid rgba(255, 255, 255, 0.7);
  color: white;
  border-radius: 50%;
  width: 152px;
  height: 152px;
  margin: 30px auto;
  text-align: center;
  vertical-align: middle;
  line-height: 200px; }

.card .content .price h4 {
  margin: 5px 0 0;
  font-size: 36px; }

.card .content .price h6 {
  margin-top: 45px;
  font-size: 16px; }

.card .content .price .currency {
  font-size: 22px;
  font-weight: normal; }

.card .actions {
  padding: 10px 15px; }

.card .more {
  float: right;
  z-index: 4;
  display: block;
  padding-top: 10px;
  padding-right: 10px;
  position: relative; }

.card .filter,
.card .header .actions {
  position: absolute;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.76);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  opacity: 0; }

.card .header .actions {
  background-color: transparent;
  z-index: 3; }

.card .header .actions .btn {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.card:hover .filter {
  opacity: 0.7; }

.card:hover .header .social-line,
.card:hover .header .actions {
  opacity: 1; }

.card .category,
.card .label {
  font-size: 14px;
  margin-bottom: 0px; }

.card .category i,
.card .label i {
  font-size: 16px; }

.card .category {
  color: #ccc5b9; }

.card .label {
  text-shadow: none; }

.card .title {
  color: #FFF; }

.card > .title {
  margin: 0;
  padding: 30px 0 0; }

.card .content {
  padding: 20px 20px 10px 20px; }

.card .content .title {
  margin: 10px 0 20px 0; }

.card .content .category ~ .title {
  margin-top: 10px; }

.card .content .description ~ .title {
  margin-top: -10px; }

.card .description {
  font-size: 16px;
  color: #FFF; }

.card h6 {
  font-size: 14px;
  margin: 0; }

.card .card-footer {
  padding: 0 15px 20px; }

.card .card-footer .social-line .btn:first-child {
  border-radius: 0 0 0 6px; }

.card .card-footer .social-line .btn:last-child {
  border-radius: 0 0 6px 0; }

.card.card-separator:after {
  height: 100%;
  right: -15px;
  top: 0;
  width: 1px;
  background-color: #dddddd;
  content: "";
  position: absolute; }

.card .icon {
  display: block;
  margin: 0 auto;
  top: 60%;
  position: relative;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  text-align: center; }

.card .icon i {
  font-size: 60px;
  padding: 18px;
  width: 110px;
  border: 2px solid #ccc5b9;
  border-radius: 50%;
  height: 110px; }

.col-lg-4 .card .icon i {
  font-size: 80px;
  padding: 22px; }

.card.card-with-border .content {
  padding: 15px 15px 25px 15px; }

.card.card-with-border .card-footer {
  padding-bottom: 25px; }

.card.card-with-border:after {
  position: absolute;
  display: block;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  content: "";
  top: 5px;
  left: 5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  z-index: 1;
  border-radius: 5px; }

.card.card-just-text .content {
  padding: 50px 65px;
  text-align: center; }

.card[data-background="image"] .image,
.card[data-background="color"] .image {
  border-radius: 6px; }

.card[data-background="image"] .title,
.card[data-background="color"] .title {
  font-weight: bold; }

.card[data-background="image"] .filter,
.card[data-background="color"] .filter {
  border-radius: 6px; }

.card[data-background="image"] .title,
.card[data-background="image"] .stats,
.card[data-background="image"] .category,
.card[data-background="image"] .description,
.card[data-background="image"] .content,
.card[data-background="image"] .card-footer,
.card[data-background="image"] small,
.card[data-background="image"] .content a,
.card[data-background="color"] .title,
.card[data-background="color"] .stats,
.card[data-background="color"] .category,
.card[data-background="color"] .description,
.card[data-background="color"] .content,
.card[data-background="color"] .card-footer,
.card[data-background="color"] small,
.card[data-background="color"] .content a {
  color: #ffffff; }

.card[data-background="image"] .content a:hover,
.card[data-background="image"] .content a:focus,
.card[data-background="color"] .content a:hover,
.card[data-background="color"] .content a:focus {
  color: #ffffff; }

.card[data-background="image"] .icon i,
.card[data-background="color"] .icon i {
  color: #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.6); }

.card[data-background="image"].card-with-border:after,
.card[data-background="color"].card-with-border:after {
  border: 1px solid rgba(255, 255, 255, 0.45); }

.card[data-background="image"] {
  text-shadow: 0 1px 10px rgba(0, 0, 0, 0.5); }

.card[data-background="image"] .filter {
  opacity: 0.55; }

.card[data-background="image"]:hover .filter {
  opacity: 0.75; }

.card[data-color="blue"] {
  background: #2980b9; }

.card[data-color="blue"] .category {
  color: #fff; }

.card[data-color="blue"] .description {
  color: #fff; }

.card[data-color="blue"] .icon i {
  color: #fff;
  border: 2px solid #fff; }

.card[data-color="green"] {
  background: #16a086; }

.card[data-color="green"] .category {
  color: #fff; }

.card[data-color="green"] .description {
  color: #fff; }

.card[data-color="green"] .icon i {
  color: #fff;
  border: 2px solid #fff; }

.card[data-color="yellow"] {
  background: #ffe28c; }

.card[data-color="yellow"] .category {
  color: #d88715; }

.card[data-color="yellow"] .description {
  color: #b25825; }

.card[data-color="yellow"] .icon i {
  color: #b25825;
  border: 2px solid #d88715; }

.card[data-color="brown"] {
  background: #d6c1ab; }

.card[data-color="brown"] .category {
  color: #a47e65; }

.card[data-color="brown"] .description {
  color: #75442e; }

.card[data-color="brown"] .icon i {
  color: #75442e;
  border: 2px solid #a47e65; }

.card[data-color="purple"] {
  background: #baa9ba; }

.card[data-color="purple"] .category {
  color: #5a283d; }

.card[data-color="purple"] .description {
  color: #3a283d; }

.card[data-color="purple"] .icon i {
  color: #3a283d;
  border: 2px solid #5a283d; }

.card[data-color="orange"] {
  background: #d35400; }

.card[data-color="orange"] .category {
  color: #fff; }

.card[data-color="orange"] .description {
  color: #fff; }

.card[data-color="orange"] .icon i {
  color: #fff;
  border: 2px solid #fff; }

.btn-center {
  text-align: center; }

.card-big-shadow {
  max-width: 320px;
  position: relative; }

.card-big-shadow:before {
  background-image: url("http://static.tumblr.com/i21wc39/coTmrkw40/shadow.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  bottom: -12%;
  content: "";
  display: block;
  left: -12%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0; }

.card-image.card-big-shadow {
  max-width: 100%; }

.card-image .card {
  background-size: cover;
  min-height: 430px;
  width: 100%; }

.card-hover-effect {
  cursor: pointer;
  -webkit-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -moz-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -o-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -ms-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease; }

.card-hover-effect:hover {
  box-shadow: 0px 12px 17px -7px rgba(0, 0, 0, 0.3);
  transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -moz-transform: translateY(-10px); }

.card-user .image {
  border-radius: 8px 8px 0 0;
  height: 150px;
  position: relative;
  overflow: hidden; }

.card-user .image img {
  width: 100%;
  height: 150px; }

.card-user .image-plain {
  height: 0;
  margin-top: 110px; }

.card-user .author {
  text-align: center;
  text-transform: none;
  margin-top: -65px; }

.card-user .author .title {
  color: #FFF; }

.card-user .author .title small {
  color: #ccc5b9; }

.card-user .avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  margin-bottom: 15px; }

.card-user .avatar.border-white {
  border: 5px solid #ffffff; }

.card-user .avatar.border-gray {
  border: 5px solid #ccc5b9; }

.card-user .title {
  line-height: 24px; }

.card-user .content {
  min-height: 240px; }

.card-user.card-plain .avatar {
  height: 190px;
  width: 190px; }

.card-image .details {
  margin-left: 4px;
  min-height: 50px;
  padding: 0 4px 0.5em;
  position: relative; }

.card-image .details .author {
  margin-top: -21px; }

.card-image .details .author .name {
  color: #FFF;
  font-size: 1.1em;
  font-weight: bold;
  line-height: 1.15;
  max-width: 11em;
  overflow: hidden;
  padding-top: 3px;
  text-overflow: ellipsis; }

.card-image .details .author .name:hover,
.card-image .details .author .name:active,
.card-image .details .author .name:focus {
  color: #FFF; }

.card-image .details .author img {
  height: 40px;
  width: 40px;
  margin-bottom: 5px; }

.card-image .details .meta {
  color: #ccc5b9;
  font-size: 0.8em; }

.card-image .details .actions {
  float: right;
  font-size: 0.875em;
  line-height: 2.6;
  position: absolute;
  right: 4px;
  top: 36px;
  z-index: 1; }

.card-image .details .actions .btn.btn-simple {
  padding-left: 2px; }

.card-image .details-center {
  text-align: center; }

.card-image .details-center .author {
  position: relative;
  display: inline-block;
  text-align: left;
  margin: 20px auto 0; }

.card-image .details-center .author img {
  position: absolute;
  left: 0;
  top: 0; }

.card-image .details-center .author .text {
  padding-left: 50px; }

.card-product .details .name {
  margin-top: 20px; }

.card-product .details .description {
  display: inline-block;
  margin-right: 60px; }

.card-product .details .actions {
  top: 20px; }

.card-product .details .actions h5 {
  color: #FFF; }

.img-proje {
  width: 128px !important;
  height: 128px !important; }

.title {
  font-size: 1.7vw !important; }

/* END CARDS PAPER */
