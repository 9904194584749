@import "../../../node_modules/compass-mixins/lib/compass";

.bubble {
  height: 100%;  
  overflow: hidden;
}

.img-bubble {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 0.2vw;
}

.img-bubble-uninfe {
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 0.3vw;
}

.img-bubble-unico {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 0.6vw;
}

.img-bubble-gemini {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 0.9vw;
}

.img-bubble:hover {
    opacity: 1.0;
    filter: alpha(opacity=100);
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.menu {
  @extend .absolute-center;
  width: 5em;
  height: 5em;

  .btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.15);
    opacity: 0;
    z-index: -10;
    cursor: pointer;
    transition: opacity 1s, z-index 0.3s, transform 1s;
    transform: translateX(0);

    .fa {
      @extend .absolute-center;
      font-size: 3em;
      transition: color 0.3s;
    }

    &:hover .fa {
      color: rgba(255, 255, 255, 0.7);
    }

    &.trigger {
      opacity: 1;
      z-index: 100;
      cursor: pointer;
      transition: transform 0.3s;

      &:hover {
        transform: scale(1.2);

        .line {
          background-color: rgba(255, 255, 255, 0.7);

          &:before,
          &:after {
            background-color: rgba(255, 255, 255, 0.7);
          }
        }
      }

      .line {
        @extend .absolute-center;
        width: 60%;
        height: 6px;
        background: #FFF;
        border-radius: 6px;
        transition: background-color 0.3s, height 0.3s, top 0.3s;

        &:before,
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          width: 100%;
          height: 6px;
          background: #FFF;
          border-radius: 6px;
          transition: background-color 0.3s, transform 0.3s;
        }
        &:before {
          top: -12px;
          transform-origin: 15% 100%;
        }
        &:after {
          top: 12px;
          transform-origin: 25% 30%;
        }
      }
    }
  }

  .rotater {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: 50% 50%;
  }

  &.active {
    .btn-icon {
      opacity: 1;
      z-index: 50;
    }

    .trigger {
      .line {
        height: 0px;
        top: 45%;

        &:before {
          transform: rotate(45deg);
          width: 110%;
        }
        &:after {
          transform: rotate(-45deg);
          width: 110%;
        }
      }
    }
  }
}

$numOfElems: 8;

@for $i from 1 through $numOfElems {
  $deg: 360deg / $numOfElems;
  .rotater:nth-child(#{$i}) {
    transform: rotate($deg/-2 + $deg * ($i - 1));
  }

  .menu.active {
    .rotater:nth-child(#{$i}) .btn-icon {
      transform: translateY(-10em) rotate($deg/2 - $deg * ($i - 1));
    }
  }
}
