@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.main-slider {    
    padding-top: 2px;
    padding-bottom: 20px;
    width: 100%;
    overflow: hidden !important;
}

.slick-image {
    max-height: 340px;
    width: 100% !important;
}

.slick-caption {
    text-align: center;
}