.support-line {
    width: 100% !important;
    height: auto !important;
    display: block;    
}

.spp-area {
    display: block;
    color: #FFF;
    text-align: center;
}

.spp-area a {
    color: #FFF;
}

.spp-area a:hover {
    color: rgb(190, 190, 190);
}

.spp-icon {
    padding-top: 5px;
    font-size: 42px;
}

.spp-title {
    font-size: 18px;
    font-weight: bold;
}


.spp-content {
    font-size: 16px;
}
