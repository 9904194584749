@import "../../assets/scss/Colors.scss";

.unimake-segment {
  background-color: #fff;
  height: 74px !important;
  display: block;
  text-align: center;
  -webkit-box-shadow: 0px 8px 34px -16px rgba(0, 0, 0, 0.53);
  -moz-box-shadow: 0px 8px 34px -16px rgba(0, 0, 0, 0.53);
  box-shadow: 0px 8px 34px -16px rgba(0, 0, 0, 0.53);  
}

.segment-button {
  height: 74px !important;
  border: none;
  margin-left: 5px !important;
  margin-right: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.segment-icon {
  padding-top: 5px;
  font-size: 42px;
}

.segment-text {
  font-size: 18px;
  text-align: center !important;
}

div .button-segment:hover {
  background-color: $hover-color;
  -webkit-box-shadow: -1px 9px 15px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 9px 15px -1px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 9px 15px -1px rgba(0, 0, 0, 0.75);
  .segment-icon {
    color: #fff;
    font-size: 44px;
  }
  .segment-text {
    color: #fff;
    font-size: 20px;
  }
}
