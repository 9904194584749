@import '../../assets/scss/Colors.scss';

.copyright-anchor {
    color: #FFF;
}

.contact-area h1, h2, h3, h4 {
    color: #FFF;
}

.our-company-area {
    color: #FFF !important;
}

.our-company-area h1, h2, h3, h4 {
    color: #FFF;
}

.twitter-area {

}

.twitter-area > h1, h2, h3, h4 {
    color: #FFF;
}

/*footer*/
.footer {
    background: $primary-color;
    color: #fff;
    padding: 50px 0;
    display: inline-block;
    width: 100%;
    font-weight: 300;
}
.footer h1 {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 20px;
}
.footer address a { color: #FFF }
.tweet-box {
    background: $primary-dark;
    padding: 8px 15px;
    border-radius: 1px;
    -moz-border-radius: 1px;
    -webkit-border-radius: 1px;
    border-bottom: 1px solid #636F79;
    font-size: 12px;
}
.tweet-box:before {
    background-color: $primary-color;
    border-color: $primary-color;
    border-image: none;
    border-right: 1px none $primary-color;
    border-style: none;
    content: "";
    display: block;
    height: 22px;
    left: 60px;
    position: absolute;
    top: 32px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 22px;
}
.tweet-box  a,
.tweet-box i { color: #00adef }
.tweet-box  a:hover { color: $primary-color }
.tweet-box i {
    font-size: 40px;
    float: left;
    margin-right: 15px;
}
.social-link-footer li {
    float: right;
    margin: 5px 10px 5px 0px;
    padding-bottom: 5px;
}
.social-link-footer li a {
    color: #fff;
    background: $primary-color;
    padding: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    float: left;
    text-align: center;
    font-size: 20px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}
.social-link-footer li a:hover {
    background: $hover-color;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}
.copyright {
    color: #fff;
    padding-top: 25px;
    padding-left: 10px;
}
.page-footer { padding: 0% 13% }
.page-footer h1 { padding-left: 4% }
ul.page-footer-list {
    list-style: none;
    padding: 0px 10px;
}
ul.page-footer-list li {
    line-height: 35px;
    border-bottom: 1px dashed rgb(148, 148, 148);
}
ul.page-footer-list a { color: #fff }
ul.page-footer-list li i { padding-right: 10px }
.footer-small {
    background-color: $primary-dark;
    margin-top: -6px;
    padding-top: 5px;
}
.text-footer {
    padding-left: 10px;
    margin-left: 10px;
}
.text-footer p { text-align: left }
.address p {
    text-align: left;
    margin-bottom: 10px;
}

.about-column {
    padding-left: 20px !important;
}