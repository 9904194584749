/* Paddings */
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pl-5 {
  padding-left: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

// Content
.main-content {
  padding-top: 64px !important;
}

.main-content .content-area {
  padding: 10px 10px !important;
  margin: 8px 8px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
}

.content-area {
  background: #fff !important;
  padding: 6px !important;
}

.content-area-wcorner {
  padding: 10px 10px !important;
  margin: 8px 8px !important;
}

.text-center {
  text-align: center;
}

.center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}


.ant-popover-placement-bottom {
  padding-top: 30px !important;
  width: 65% !important;
}



@media only screen and (max-width: 768px) {
  .ant-popover-placement-bottom {
    padding-top: 65px !important;
    width: 95% !important;
    z-index: 99999 !important;
    margin-left: 100% !important;
  }
}