@import '../../assets/scss/Colors.scss';

.feature-box {
    margin-top: 15px;
    margin-bottom: 15px;
    padding-bottom: 10px;
    padding-top: 10px;
}

.feature-box-heading i {
    color: $primary-dark;
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 50px;
    border: 1px solid $primary-dark;
    line-height: 40px;
}

.title-feature {
    font-size: 20px !important;
    color: #797979;
    text-align: center !important;
}

.text-feature {
    font-size: 16px !important;
}
