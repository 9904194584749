.pl-banner {
  height: 400px;
}

.pl-banner-2 {
  height: 250px;
  text-align: center;
  color: #fff;
  font-size: 1.5vw;
  background: rgba(83, 83, 83, 0.911);
  overflow: hidden;
}

.phrase-product {
  text-transform: uppercase;
  font-size: 2.0em;
  color: #4a4f66 !important;
  text-align:  center;
}
