@import "./assets/scss/Colors.scss";
@import "./assets/scss/Main.scss";
@import "./assets/scss/Responsive.scss";
@import "./assets/scss/AcmeTemplate.scss";

body {
    color: #797979;
    font-family: 'Lato', sans-serif;
    overflow-x: hidden;
}
a,
a:hover,
a:focus {
    text-decoration: none;
    outline: none;
}
::selection {
    background: $primary-dark;
    color: #fff;
}
::-moz-selection {
    background: $primary-dark;
    color: #fff;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Lato', sans-serif;
    color: #797979
    /*font-family: 'Open Sans', sans-serif;*/
}