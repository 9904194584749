body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* COLORS */
/* Paddings */
.pr-5 {
  padding-right: 5px; }

.pr-10 {
  padding-right: 10px; }

.pr-15 {
  padding-right: 15px; }

.pl-5 {
  padding-left: 10px; }

.pl-10 {
  padding-left: 10px; }

.pl-15 {
  padding-left: 15px; }

.main-content {
  padding-top: 64px !important; }

.main-content .content-area {
  padding: 10px 10px !important;
  margin: 8px 8px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 5px;
  /* 5px rounded corners */ }

.content-area {
  background: #fff !important;
  padding: 6px !important; }

.content-area-wcorner {
  padding: 10px 10px !important;
  margin: 8px 8px !important; }

.text-center {
  text-align: center; }

.center {
  margin-left: auto;
  margin-right: auto;
  display: block; }

.ant-popover-placement-bottom {
  padding-top: 30px !important;
  width: 65% !important; }

@media only screen and (max-width: 768px) {
  .ant-popover-placement-bottom {
    padding-top: 65px !important;
    width: 95% !important;
    z-index: 99999 !important;
    margin-left: 100% !important; } }

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px; }

ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline > li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px; }

body {
  color: #797979;
  font-family: 'Lato', sans-serif;
  overflow-x: hidden; }

a,
a:hover,
a:focus {
  text-decoration: none;
  outline: none; }

::-moz-selection {
  background: #136295;
  color: #fff; }

::selection {
  background: #136295;
  color: #fff; }

::-moz-selection {
  background: #136295;
  color: #fff; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', sans-serif;
  color: #797979; }

/*  Header */
.main-header {
  padding-left: 25px;
  padding-right: 15px;
  background-color: white;
  height: 66px !important;
  border-bottom: 1px solid #e8e8e8 !important;
  position: fixed;
  z-index: 999999;
  width: 100%;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.082) !important;
}

.main-content {
  padding-top: 64px !important;
}

.principal-slider {
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 100% !important; }

.product-card {
  width: 350px;
  margin-left: auto;
  margin-right: auto;
  display: block; }

/* COLORS */
.feature-box {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  padding-top: 10px; }

.feature-box-heading i {
  color: #136295;
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 50px;
  border: 1px solid #136295;
  line-height: 40px; }

.title-feature {
  font-size: 20px !important;
  color: #797979;
  text-align: center !important; }

.text-feature {
  font-size: 16px !important; }

/* CARDS PAPER */
.card {
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5);
  color: #FFF;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  width: 350px; }

.card .title,
.card .stats,
.card .category,
.card .description,
.card .social-line,
.card .actions,
.card .content,
.card .card-footer,
.card small,
.card a {
  position: relative;
  z-index: 3; }

.card a {
  color: #444444; }

.card a:hover,
.card a:focus {
  color: #333333; }

.card img {
  max-width: 100%;
  height: auto; }

.card[data-radius="none"] {
  border-radius: 0px; }

.card[data-radius="none"] .header {
  border-radius: 0px 0px 0 0; }

.card[data-radius="none"] .header img {
  border-radius: 0px 0px 0 0; }

.card.card-plain {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0; }

.card.card-with-shadow {
  box-shadow: 0 20px 16px -15px rgba(165, 165, 165, 0.57); }

.card .btn {
  text-shadow: none;
  font-weight: bold; }

.card .title-uppercase {
  text-transform: uppercase; }

.card .header {
  position: relative;
  border-radius: 3px 3px 0 0;
  z-index: 3; }

.card .header.header-with-icon {
  height: 150px; }

.card .header img {
  width: 100%;
  /*
                      @include opacity(0);
                      display: none;
          */ }

.card .header .category {
  padding: 15px; }

.card .content .price {
  border: 2px solid rgba(255, 255, 255, 0.7);
  color: white;
  border-radius: 50%;
  width: 152px;
  height: 152px;
  margin: 30px auto;
  text-align: center;
  vertical-align: middle;
  line-height: 200px; }

.card .content .price h4 {
  margin: 5px 0 0;
  font-size: 36px; }

.card .content .price h6 {
  margin-top: 45px;
  font-size: 16px; }

.card .content .price .currency {
  font-size: 22px;
  font-weight: normal; }

.card .actions {
  padding: 10px 15px; }

.card .more {
  float: right;
  z-index: 4;
  display: block;
  padding-top: 10px;
  padding-right: 10px;
  position: relative; }

.card .filter,
.card .header .actions {
  position: absolute;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.76);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  opacity: 0; }

.card .header .actions {
  background-color: transparent;
  z-index: 3; }

.card .header .actions .btn {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.card:hover .filter {
  opacity: 0.7; }

.card:hover .header .social-line,
.card:hover .header .actions {
  opacity: 1; }

.card .category,
.card .label {
  font-size: 14px;
  margin-bottom: 0px; }

.card .category i,
.card .label i {
  font-size: 16px; }

.card .category {
  color: #ccc5b9; }

.card .label {
  text-shadow: none; }

.card .title {
  color: #FFF; }

.card > .title {
  margin: 0;
  padding: 30px 0 0; }

.card .content {
  padding: 20px 20px 10px 20px; }

.card .content .title {
  margin: 10px 0 20px 0; }

.card .content .category ~ .title {
  margin-top: 10px; }

.card .content .description ~ .title {
  margin-top: -10px; }

.card .description {
  font-size: 16px;
  color: #FFF; }

.card h6 {
  font-size: 14px;
  margin: 0; }

.card .card-footer {
  padding: 0 15px 20px; }

.card .card-footer .social-line .btn:first-child {
  border-radius: 0 0 0 6px; }

.card .card-footer .social-line .btn:last-child {
  border-radius: 0 0 6px 0; }

.card.card-separator:after {
  height: 100%;
  right: -15px;
  top: 0;
  width: 1px;
  background-color: #dddddd;
  content: "";
  position: absolute; }

.card .icon {
  display: block;
  margin: 0 auto;
  top: 60%;
  position: relative;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  text-align: center; }

.card .icon i {
  font-size: 60px;
  padding: 18px;
  width: 110px;
  border: 2px solid #ccc5b9;
  border-radius: 50%;
  height: 110px; }

.col-lg-4 .card .icon i {
  font-size: 80px;
  padding: 22px; }

.card.card-with-border .content {
  padding: 15px 15px 25px 15px; }

.card.card-with-border .card-footer {
  padding-bottom: 25px; }

.card.card-with-border:after {
  position: absolute;
  display: block;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  content: "";
  top: 5px;
  left: 5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  z-index: 1;
  border-radius: 5px; }

.card.card-just-text .content {
  padding: 50px 65px;
  text-align: center; }

.card[data-background="image"] .image,
.card[data-background="color"] .image {
  border-radius: 6px; }

.card[data-background="image"] .title,
.card[data-background="color"] .title {
  font-weight: bold; }

.card[data-background="image"] .filter,
.card[data-background="color"] .filter {
  border-radius: 6px; }

.card[data-background="image"] .title,
.card[data-background="image"] .stats,
.card[data-background="image"] .category,
.card[data-background="image"] .description,
.card[data-background="image"] .content,
.card[data-background="image"] .card-footer,
.card[data-background="image"] small,
.card[data-background="image"] .content a,
.card[data-background="color"] .title,
.card[data-background="color"] .stats,
.card[data-background="color"] .category,
.card[data-background="color"] .description,
.card[data-background="color"] .content,
.card[data-background="color"] .card-footer,
.card[data-background="color"] small,
.card[data-background="color"] .content a {
  color: #ffffff; }

.card[data-background="image"] .content a:hover,
.card[data-background="image"] .content a:focus,
.card[data-background="color"] .content a:hover,
.card[data-background="color"] .content a:focus {
  color: #ffffff; }

.card[data-background="image"] .icon i,
.card[data-background="color"] .icon i {
  color: #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.6); }

.card[data-background="image"].card-with-border:after,
.card[data-background="color"].card-with-border:after {
  border: 1px solid rgba(255, 255, 255, 0.45); }

.card[data-background="image"] {
  text-shadow: 0 1px 10px rgba(0, 0, 0, 0.5); }

.card[data-background="image"] .filter {
  opacity: 0.55; }

.card[data-background="image"]:hover .filter {
  opacity: 0.75; }

.card[data-color="blue"] {
  background: #2980b9; }

.card[data-color="blue"] .category {
  color: #fff; }

.card[data-color="blue"] .description {
  color: #fff; }

.card[data-color="blue"] .icon i {
  color: #fff;
  border: 2px solid #fff; }

.card[data-color="green"] {
  background: #16a086; }

.card[data-color="green"] .category {
  color: #fff; }

.card[data-color="green"] .description {
  color: #fff; }

.card[data-color="green"] .icon i {
  color: #fff;
  border: 2px solid #fff; }

.card[data-color="yellow"] {
  background: #ffe28c; }

.card[data-color="yellow"] .category {
  color: #d88715; }

.card[data-color="yellow"] .description {
  color: #b25825; }

.card[data-color="yellow"] .icon i {
  color: #b25825;
  border: 2px solid #d88715; }

.card[data-color="brown"] {
  background: #d6c1ab; }

.card[data-color="brown"] .category {
  color: #a47e65; }

.card[data-color="brown"] .description {
  color: #75442e; }

.card[data-color="brown"] .icon i {
  color: #75442e;
  border: 2px solid #a47e65; }

.card[data-color="purple"] {
  background: #baa9ba; }

.card[data-color="purple"] .category {
  color: #5a283d; }

.card[data-color="purple"] .description {
  color: #3a283d; }

.card[data-color="purple"] .icon i {
  color: #3a283d;
  border: 2px solid #5a283d; }

.card[data-color="orange"] {
  background: #d35400; }

.card[data-color="orange"] .category {
  color: #fff; }

.card[data-color="orange"] .description {
  color: #fff; }

.card[data-color="orange"] .icon i {
  color: #fff;
  border: 2px solid #fff; }

.btn-center {
  text-align: center; }

.card-big-shadow {
  max-width: 320px;
  position: relative; }

.card-big-shadow:before {
  background-image: url("http://static.tumblr.com/i21wc39/coTmrkw40/shadow.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  bottom: -12%;
  content: "";
  display: block;
  left: -12%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0; }

.card-image.card-big-shadow {
  max-width: 100%; }

.card-image .card {
  background-size: cover;
  min-height: 430px;
  width: 100%; }

.card-hover-effect {
  cursor: pointer;
  -webkit-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -webkit-transition: box-shadow 200ms ease, -webkit-transform 300ms cubic-bezier(0.34, 2, 0.6, 1);
  transition: box-shadow 200ms ease, -webkit-transform 300ms cubic-bezier(0.34, 2, 0.6, 1);
  transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease, -webkit-transform 300ms cubic-bezier(0.34, 2, 0.6, 1); }

.card-hover-effect:hover {
  box-shadow: 0px 12px 17px -7px rgba(0, 0, 0, 0.3);
  transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -moz-transform: translateY(-10px); }

.card-user .image {
  border-radius: 8px 8px 0 0;
  height: 150px;
  position: relative;
  overflow: hidden; }

.card-user .image img {
  width: 100%;
  height: 150px; }

.card-user .image-plain {
  height: 0;
  margin-top: 110px; }

.card-user .author {
  text-align: center;
  text-transform: none;
  margin-top: -65px; }

.card-user .author .title {
  color: #FFF; }

.card-user .author .title small {
  color: #ccc5b9; }

.card-user .avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  margin-bottom: 15px; }

.card-user .avatar.border-white {
  border: 5px solid #ffffff; }

.card-user .avatar.border-gray {
  border: 5px solid #ccc5b9; }

.card-user .title {
  line-height: 24px; }

.card-user .content {
  min-height: 240px; }

.card-user.card-plain .avatar {
  height: 190px;
  width: 190px; }

.card-image .details {
  margin-left: 4px;
  min-height: 50px;
  padding: 0 4px 0.5em;
  position: relative; }

.card-image .details .author {
  margin-top: -21px; }

.card-image .details .author .name {
  color: #FFF;
  font-size: 1.1em;
  font-weight: bold;
  line-height: 1.15;
  max-width: 11em;
  overflow: hidden;
  padding-top: 3px;
  text-overflow: ellipsis; }

.card-image .details .author .name:hover,
.card-image .details .author .name:active,
.card-image .details .author .name:focus {
  color: #FFF; }

.card-image .details .author img {
  height: 40px;
  width: 40px;
  margin-bottom: 5px; }

.card-image .details .meta {
  color: #ccc5b9;
  font-size: 0.8em; }

.card-image .details .actions {
  float: right;
  font-size: 0.875em;
  line-height: 2.6;
  position: absolute;
  right: 4px;
  top: 36px;
  z-index: 1; }

.card-image .details .actions .btn.btn-simple {
  padding-left: 2px; }

.card-image .details-center {
  text-align: center; }

.card-image .details-center .author {
  position: relative;
  display: inline-block;
  text-align: left;
  margin: 20px auto 0; }

.card-image .details-center .author img {
  position: absolute;
  left: 0;
  top: 0; }

.card-image .details-center .author .text {
  padding-left: 50px; }

.card-product .details .name {
  margin-top: 20px; }

.card-product .details .description {
  display: inline-block;
  margin-right: 60px; }

.card-product .details .actions {
  top: 20px; }

.card-product .details .actions h5 {
  color: #FFF; }

.img-proje {
  width: 128px !important;
  height: 128px !important; }

.title {
  font-size: 1.7vw !important; }

/* END CARDS PAPER */

.main-slider {
  padding-top: 2px;
  padding-bottom: 20px;
  width: 100%;
  overflow: hidden !important; }

.slick-image {
  max-height: 340px;
  width: 100% !important; }

.slick-caption {
  text-align: center; }

.support-line {
  width: 100% !important;
  height: auto !important;
  display: block; }

.spp-area {
  display: block;
  color: #FFF;
  text-align: center; }

.spp-area a {
  color: #FFF; }

.spp-area a:hover {
  color: #bebebe; }

.spp-icon {
  padding-top: 5px;
  font-size: 42px; }

.spp-title {
  font-size: 18px;
  font-weight: bold; }

.spp-content {
  font-size: 16px; }


#components-form-demo-normal-login .login-form {
  max-width: 300px; }

#components-form-demo-normal-login .login-form-forgot {
  float: right; }

#components-form-demo-normal-login .login-form-button {
  width: 100%; }


table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed; }

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em; }

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.35em; }

table th,
table td {
  padding: 0.625em;
  text-align: center; }

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase; }

@media screen and (max-width: 600px) {
  table {
    border: 0; }
  table caption {
    font-size: 1.3em; }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em; }
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right; }
  table td::before {
    /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase; }
  table td:last-child {
    border-bottom: 0; } }

.logo {
  width: 150px;
  float: left; }

.logo a {
  display: inline-block;
  font-size: 20px;
  text-transform: capitalize; }

.logoImage {
  width: 150px; }

.menuCon {
  width: calc(100% - 150px);
  float: left; }

.menuCon .leftMenu {
  float: left;
  margin-left: 10px; }

.menuCon .rightMenu {
  float: right; }

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 20px;
  display: none !important;
  background-color: #FFF !important;
  color: gray !important;
  border-color: #d0d0d0 !important; }

.bar-menu {
  -webkit-transform: scale(1.8, 1.1);
          transform: scale(1.8, 1.1); }

.barsBtn {
  display: block;
  width: 20px;
  height: 4px;
  background: #d0d0d0;
  position: relative; }

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -8px;
  left: 0;
  height: 2px;
  background: #d0d0d0; }

.barsBtn:after {
  top: auto;
  bottom: -8px; }

.ant-drawer-body {
  padding: 0; }

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%; }

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none; }

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent; }

@media only screen and (max-width: 768px) {
  .barsMenu {
    display: inline-block !important; }
  .leftMenu,
  .rightMenu {
    display: none; }
  .logo a {
    margin-left: -20px; }
  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px; } }

/* COLORS */
.copyright-anchor {
  color: #FFF; }

.contact-area h1, h2, h3, h4 {
  color: #FFF; }

.our-company-area {
  color: #FFF !important; }

.our-company-area h1, h2, h3, h4 {
  color: #FFF; }

.twitter-area > h1, h2, h3, h4 {
  color: #FFF; }

/*footer*/
.footer {
  background: #216da0;
  color: #fff;
  padding: 50px 0;
  display: inline-block;
  width: 100%;
  font-weight: 300; }

.footer h1 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 20px; }

.footer address a {
  color: #FFF; }

.tweet-box {
  background: #136295;
  padding: 8px 15px;
  border-radius: 1px;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  border-bottom: 1px solid #636F79;
  font-size: 12px; }

.tweet-box:before {
  background-color: #216da0;
  border-color: #216da0;
  -webkit-border-image: none;
          border-image: none;
  border-right: 1px none #216da0;
  border-style: none;
  content: "";
  display: block;
  height: 22px;
  left: 60px;
  position: absolute;
  top: 32px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 22px; }

.tweet-box a,
.tweet-box i {
  color: #00adef; }

.tweet-box a:hover {
  color: #216da0; }

.tweet-box i {
  font-size: 40px;
  float: left;
  margin-right: 15px; }

.social-link-footer li {
  float: right;
  margin: 5px 10px 5px 0px;
  padding-bottom: 5px; }

.social-link-footer li a {
  color: #fff;
  background: #216da0;
  padding: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  float: left;
  text-align: center;
  font-size: 20px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.social-link-footer li a:hover {
  background: #519bcc;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.copyright {
  color: #fff;
  padding-top: 25px;
  padding-left: 10px; }

.page-footer {
  padding: 0% 13%; }

.page-footer h1 {
  padding-left: 4%; }

ul.page-footer-list {
  list-style: none;
  padding: 0px 10px; }

ul.page-footer-list li {
  line-height: 35px;
  border-bottom: 1px dashed #949494; }

ul.page-footer-list a {
  color: #fff; }

ul.page-footer-list li i {
  padding-right: 10px; }

.footer-small {
  background-color: #136295;
  margin-top: -6px;
  padding-top: 5px; }

.text-footer {
  padding-left: 10px;
  margin-left: 10px; }

.text-footer p {
  text-align: left; }

.address p {
  text-align: left;
  margin-bottom: 10px; }

.about-column {
  padding-left: 20px !important; }


.go-top {
  margin-right: 0 !important; }

.bubble-area {
  height: 500px;
  background: #000; }

.bg-img {
  height: 58%; }

.bubble {
  height: 100%;
  overflow: hidden; }

.img-bubble {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 0.2vw; }

.img-bubble-uninfe {
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 0.3vw; }

.img-bubble-unico {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 0.6vw; }

.img-bubble-gemini {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 0.9vw; }

.img-bubble:hover {
  opacity: 1.0;
  filter: alpha(opacity=100); }

.absolute-center, .menu, .menu .btn .fa, .menu .btn.trigger .line {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%); }

.menu {
  width: 5em;
  height: 5em; }
  .menu .btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.15);
    opacity: 0;
    z-index: -10;
    cursor: pointer;
    -webkit-transition: opacity 1s, z-index 0.3s, -webkit-transform 1s;
    transition: opacity 1s, z-index 0.3s, -webkit-transform 1s;
    transition: opacity 1s, z-index 0.3s, transform 1s;
    transition: opacity 1s, z-index 0.3s, transform 1s, -webkit-transform 1s;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
    .menu .btn .fa {
      font-size: 3em;
      -webkit-transition: color 0.3s;
      transition: color 0.3s; }
    .menu .btn:hover .fa {
      color: rgba(255, 255, 255, 0.7); }
    .menu .btn.trigger {
      opacity: 1;
      z-index: 100;
      cursor: pointer;
      -webkit-transition: -webkit-transform 0.3s;
      transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      transition: transform 0.3s, -webkit-transform 0.3s; }
      .menu .btn.trigger:hover {
        -webkit-transform: scale(1.2);
                transform: scale(1.2); }
        .menu .btn.trigger:hover .line {
          background-color: rgba(255, 255, 255, 0.7); }
          .menu .btn.trigger:hover .line:before, .menu .btn.trigger:hover .line:after {
            background-color: rgba(255, 255, 255, 0.7); }
      .menu .btn.trigger .line {
        width: 60%;
        height: 6px;
        background: #FFF;
        border-radius: 6px;
        -webkit-transition: background-color 0.3s, height 0.3s, top 0.3s;
        transition: background-color 0.3s, height 0.3s, top 0.3s; }
        .menu .btn.trigger .line:before, .menu .btn.trigger .line:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          width: 100%;
          height: 6px;
          background: #FFF;
          border-radius: 6px;
          -webkit-transition: background-color 0.3s, -webkit-transform 0.3s;
          transition: background-color 0.3s, -webkit-transform 0.3s;
          transition: background-color 0.3s, transform 0.3s;
          transition: background-color 0.3s, transform 0.3s, -webkit-transform 0.3s; }
        .menu .btn.trigger .line:before {
          top: -12px;
          -webkit-transform-origin: 15% 100%;
                  transform-origin: 15% 100%; }
        .menu .btn.trigger .line:after {
          top: 12px;
          -webkit-transform-origin: 25% 30%;
                  transform-origin: 25% 30%; }
  .menu .rotater {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%; }
  .menu.active .btn-icon {
    opacity: 1;
    z-index: 50; }
  .menu.active .trigger .line {
    height: 0px;
    top: 45%; }
    .menu.active .trigger .line:before {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      width: 110%; }
    .menu.active .trigger .line:after {
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      width: 110%; }

.rotater:nth-child(1) {
  -webkit-transform: rotate(-22.5deg);
          transform: rotate(-22.5deg); }

.menu.active .rotater:nth-child(1) .btn-icon {
  -webkit-transform: translateY(-10em) rotate(22.5deg);
          transform: translateY(-10em) rotate(22.5deg); }

.rotater:nth-child(2) {
  -webkit-transform: rotate(22.5deg);
          transform: rotate(22.5deg); }

.menu.active .rotater:nth-child(2) .btn-icon {
  -webkit-transform: translateY(-10em) rotate(-22.5deg);
          transform: translateY(-10em) rotate(-22.5deg); }

.rotater:nth-child(3) {
  -webkit-transform: rotate(67.5deg);
          transform: rotate(67.5deg); }

.menu.active .rotater:nth-child(3) .btn-icon {
  -webkit-transform: translateY(-10em) rotate(-67.5deg);
          transform: translateY(-10em) rotate(-67.5deg); }

.rotater:nth-child(4) {
  -webkit-transform: rotate(112.5deg);
          transform: rotate(112.5deg); }

.menu.active .rotater:nth-child(4) .btn-icon {
  -webkit-transform: translateY(-10em) rotate(-112.5deg);
          transform: translateY(-10em) rotate(-112.5deg); }

.rotater:nth-child(5) {
  -webkit-transform: rotate(157.5deg);
          transform: rotate(157.5deg); }

.menu.active .rotater:nth-child(5) .btn-icon {
  -webkit-transform: translateY(-10em) rotate(-157.5deg);
          transform: translateY(-10em) rotate(-157.5deg); }

.rotater:nth-child(6) {
  -webkit-transform: rotate(202.5deg);
          transform: rotate(202.5deg); }

.menu.active .rotater:nth-child(6) .btn-icon {
  -webkit-transform: translateY(-10em) rotate(-202.5deg);
          transform: translateY(-10em) rotate(-202.5deg); }

.rotater:nth-child(7) {
  -webkit-transform: rotate(247.5deg);
          transform: rotate(247.5deg); }

.menu.active .rotater:nth-child(7) .btn-icon {
  -webkit-transform: translateY(-10em) rotate(-247.5deg);
          transform: translateY(-10em) rotate(-247.5deg); }

.rotater:nth-child(8) {
  -webkit-transform: rotate(292.5deg);
          transform: rotate(292.5deg); }

.menu.active .rotater:nth-child(8) .btn-icon {
  -webkit-transform: translateY(-10em) rotate(-292.5deg);
          transform: translateY(-10em) rotate(-292.5deg); }

.pl-banner {
  height: 400px; }

.pl-banner-2 {
  height: 250px;
  text-align: center;
  color: #fff;
  font-size: 1.5vw;
  background: rgba(83, 83, 83, 0.911);
  overflow: hidden; }

.phrase-product {
  text-transform: uppercase;
  font-size: 2.0em;
  color: #4a4f66 !important;
  text-align: center; }

/* COLORS */
.unimake-segment {
  background-color: #fff;
  height: 74px !important;
  display: block;
  text-align: center;
  box-shadow: 0px 8px 34px -16px rgba(0, 0, 0, 0.53); }

.segment-button {
  height: 74px !important;
  border: none;
  margin-left: 5px !important;
  margin-right: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important; }

.segment-icon {
  padding-top: 5px;
  font-size: 42px; }

.segment-text {
  font-size: 18px;
  text-align: center !important; }

div .button-segment:hover {
  background-color: #519bcc;
  box-shadow: -1px 9px 15px -1px rgba(0, 0, 0, 0.75); }
  div .button-segment:hover .segment-icon {
    color: #fff;
    font-size: 44px; }
  div .button-segment:hover .segment-text {
    color: #fff;
    font-size: 20px; }

