.principal-slider {
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 100% !important;
}

.product-card {
  width: 350px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
