.logo {
  width: 150px;
  float: left;
}

.logo a {
  display: inline-block;
  font-size: 20px;
  text-transform: capitalize;
}

.logoImage {
  width: 150px;
}

.menuCon {
  width: calc(100% - 150px);
  float: left;
}

.menuCon .leftMenu {
  float: left;
  margin-left: 10px;
}

.menuCon .rightMenu {
  float: right;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 20px;
  display: none !important;  
  background-color: #FFF !important;
  color: gray !important;
  border-color: #d0d0d0 !important;
}

.bar-menu{
  transform: scale(1.8,1.1);
}

.barsBtn {
  display: block;
  width: 20px;
  height: 4px;
  background: #d0d0d0;
  position: relative;  
}
.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -8px;
  left: 0;
  height: 2px;
  background: #d0d0d0;
}
.barsBtn:after {
  top: auto;
  bottom: -8px;
}
.ant-drawer-body {
  padding: 0;
}
.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}
.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}
.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}

@media only screen and (max-width: 768px) {
  .barsMenu {
    display: inline-block !important;
  }
  .leftMenu,
  .rightMenu {
    display: none;
  }
  .logo a {
    margin-left: -20px;
  }
  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }
}