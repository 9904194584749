@import "~antd/dist/antd.css";

/*  Header */
.main-header {
  padding-left: 25px;
  padding-right: 15px;
  background-color: white;
  height: 66px !important;
  border-bottom: 1px solid #e8e8e8 !important;
  position: fixed;
  z-index: 999999;
  width: 100%;
  -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.082) !important;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.082) !important;
}

.main-content {
  padding-top: 64px !important;
}
